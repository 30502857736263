body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    background-color: #f8f8f8;
}
html {
  overflow-y: scroll;
}


.navbar-header {
    padding-top: 4px;
    padding-bottom: 4px;
}
.navbar li a {
    padding-top: 18px;
    font-weight: 500;
    font-size: 15px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
}

.navbar {
    x-moz-box-shadow: 0 0 10px 2px rgba(0,0,0,.05);
    x-webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.05);
    box-shadow: 0 0 10px 2px rgba(0,0,0,.05);
}

#footer {
    text-align: center
}

#footer .top {
    background: #2e2b2b;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 40px 0 27px;
}

#footer .top li {
    display: inline-block;
    margin: 0 30px 10px;
}

#footer .top a {
    color: #fff;
    text-decoration: none;
}

#footer .bottom {
    border-top: 1px solid #5f5d5d;
    background: #211f1f;
    font-size: 11px;
    font-weight: 400;
    color: #636262;
    padding: 28px 0;
}

#footer .bottom a {
    color: #636262;
}

#footer .menu-item-31 a:before {
    content: '';
    display: inline-block;
    width: 9px;
    height: 15px;
    background: url('../images/social/facebook.svg') no-repeat;
    margin: 0 6px 0 0;
    position: relative;
    top: 3px;
}

#footer .menu-item-32 a:before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    background: url('../images/social/twitter.svg') no-repeat;
    margin: 0 6px 0 0;
    position: relative;
    top: 3px;
}

#footer .menu-item-33 a:before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    background: url('../images/social/github.png') no-repeat;
    margin: 0 6px 0 0;
    position: relative;
    top: 3px;
}

/* Hide bootstrap sort header icons */
table.table thead .sorting:after { content: '' !important }
table.table thead .sorting_asc:after { content: '' !important }
table.table thead .sorting_desc:after { content: '' !important }
table.table thead .sorting_asc_disabled:after { content: '' !important }
table.table thead .sorting_desc_disabled:after { content: '' !important }

.dataTables_length {
    padding-left: 20px;
    padding-top: 8px;
}

.dataTables_length label {
    font-weight: 500;
}

@media screen and (min-width: 700px) {
    #footer .top {
        padding: 27px 0;
    }

    #footer .bottom {
        padding: 25px 0;
    }
}



table.dataTable { border-radius: 3px; border-collapse: collapse;
/*border-spacing: 0;*/}
table.dataTable thead > tr > th, table.invoice-table thead > tr > th {
    color:#fff;
}
th:first-child {
    border-radius: 3px 0 0 0;
    border-left: none;
}
th:last-child {
    border-radius: 0 3px 0 0;
}

tr {border: none;}
td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

/*th {border-left: 1px solid #d26b26; }*/
th {border-left: 1px solid #FFFFFF; }
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    vertical-align: middle;
    border-top: none;
    border-bottom: 1px solid #dfe0e1;
}
table.dataTable.no-footer {
    border-bottom: none;
}
.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #FDFDFD;
}
table.table thead .sorting_asc {
    background: url('../images/sort_asc.png') no-repeat 90% 50%;
}
table.table thead .sorting_desc {
    background: url('../images/sort_desc.png') no-repeat 90% 50%;
}
table.dataTable thead th, table.dataTable thead td, table.invoice-table thead th, table.invoice-table thead td {
    padding: 12px 10px;
}
table.dataTable tbody th, table.dataTable tbody td {
    padding: 10px;
}

.dataTables_wrapper {
    padding-top: 16px;
}

table.table thead > tr > th {
    border-bottom-width: 0px;
}

table td {
    max-width: 250px;
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

/* hide table sorting indicators */
table.data-table thead .sorting { background: url('') no-repeat center right; }
